<template>
  <div>
    <!-- 标题 -->
    <!-- <div>
      <van-nav-bar title="资讯中心" />
    </div> -->
    <!-- tab栏 -->
    <div class="information">
      <van-tabs v-model="riKind"
        @click="onClick"
        color="#1989fa"
        style="border: 10px solid rgb(247, 247, 247)">
        <van-tab title="疫苗接种"
          name="2">
          <!-- 疫苗接种 -->
          <div class="center"
            v-for="item in ymlist"
            :key="item.index">
            <!-- @click="xiangqing(item.ID) <router-link :to="'/article/detail/' + item.RIId"> -->
            <div class="Content"
              style="padding: 8px 10px; line-height: 30px">
              <div style="display: inline-block">
                <span style="font-size: 18px; color: #333">
                  <van-icon name="manager" />{{ item.Name }}
                  <van-icon name="phone" />{{ item.Mobile }}</span>
                <br />

                <span class="centertime"
                  style="font-size: 18px; color: #333">
                  报备时间：{{ item.AddTime }}
                </span>
                <br />
                <span class="centertime"
                  style="font-size: 18px; color: #333">
                  接种情况：{{ item.StatusDesc }} </span><br />
                <span class="centertime"
                  style="font-size: 18px; color: #333">
                  接种时间：{{
                    item.VaccinateTime | capitalizeTime("电话号码")
                  }}
                </span>
              </div>
              <div style="display: inline-block; width: 95%; text-align: right"
                class="ContentB">
                <span class="centertime"
                  style="color: #333">
                  <van-button type="info"
                    round
                    style="width: 80px; font-size: 13px"
                    @click="yimiaoUpdata(item)"
                    size="small">更新</van-button>
                </span>
              </div>
            </div>
            <!-- </router-link> -->
          </div>
        </van-tab>
        <van-tab title="核酸检测"
          name="1">
          <div class="center"
            v-for="item in hslist"
            :key="item.index">
            <div style="padding: 8px 10px; line-height: 30px">
              <div style="display: inline-block"
                @click="hesuanXq(item)">
                <span style="font-size: 18px; color: #333">
                  <van-icon name="manager" />{{ item.Name }}
                  <van-icon name="phone" />{{ item.Mobile }}</span>
                <br />

                <span class="centertime"
                  style="font-size: 18px; color: #333">
                  报备时间：{{ item.AddTime }}
                </span>
                <br />
                <span class="centertime"
                  style="font-size: 18px; color: #333">
                  住址：{{ item.Address }}
                </span>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="返洛报备"
          name="0">
          <van-list v-model="loading"
            :finished="finished"
            offset="50">
            <div class="center"
              v-for="item in list"
              :key="item.index">
              <div class="Content"
                style="padding: 8px 10px; line-height: 30px">
                <div style="display: inline-block"
                  @click="fanqixiangqing(item.ID)">
                  <span style="font-size: 18px; color: #333">
                    <van-icon name="manager" />{{ item.Name }}
                    <van-icon name="phone" />{{ item.Mobile }}</span>
                  <br />

                  <span class="centertime"
                    style="font-size: 18px; color: #333">
                    报备时间：{{ item.AddTime }}
                  </span>
                  <br />
                  <span class="centertime"
                    style="font-size: 18px; color: #333">
                    停留地点：{{ item.Address }}
                  </span>
                </div>
                <div style="display: inline-block; width: 95%; text-align: right"
                  class="ContentB">
                  <span class="centertime"
                    style="color: #333">
                    <van-button type="info"
                      round
                      style="width: 80px; font-size: 13px"
                      @click="fanqiUpdata(item.ID)"
                      size="small">核酸报备</van-button>
                  </span>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
        <!-- <van-tab title="在外人员"
          name="1">
          <div class="center"
            v-for="item in zaiwailist"
            :key="item.index">
            <div style="padding: 8px 10px; line-height: 30px">
              <div style="display: inline-block"
                @click="zaiwaixiangqing(item)">
                <span style="font-size: 18px; color: #333">
                  <van-icon name="manager" />{{ item.Name }}
                  <van-icon name="phone" />{{ item.Mobile }}</span>
                <br />

                <span class="centertime"
                  style="font-size: 18px; color: #333">
                  报备时间：{{ item.AddTime }}
                </span>
                <br />
                <span class="centertime"
                  style="font-size: 18px; color: #333">
                  重点停留地：{{ item.Address }}
                </span>
              </div>
            </div>
          </div>
        </van-tab> -->

      </van-tabs>
      <!-- 没有更多数据了 -->
      <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div>
    </div>
    <van-dialog v-model="showTc"
      title="提示"
      @confirm="confirm"
      show-cancel-button>
      <van-cell-group>
        <van-field v-model="datafrom.StatusDesc"
          required
          @click="Status = true"
          readonly="readonly"
          label="接种疫苗情况"
          placeholder="请选择接种疫苗情况" />
        <van-popup v-model="Status"
          position="bottom"
          get-container="body">
          <van-picker show-toolbar
            :columns="yimiaolist"
            value-key="Name"
            @cancel="Status = false"
            @confirm="onStatus">
          </van-picker>
        </van-popup>
      </van-cell-group>
      <van-field v-model="datafrom.VaccinateTime"
        name="VaccinateTime"
        required
        label="接种时间"
        @click="jiezhongTime = true"
        readonly="readonly"
        placeholder="请选择接种时间" />
      <van-popup v-model="jiezhongTime"
        position="bottom"
        get-container="body">
        <van-datetime-picker v-model="jiezhognDate"
          show-toolbar
          type="date"
          title="请选择接种时间"
          :min-date="minDate"
          :max-date="maxDate"
          @cancel="jiezhongTime = false"
          @confirm="onjiezhongFend">
        </van-datetime-picker>
      </van-popup>
    </van-dialog>
  </div>
</template>
<script>
import {
  WxGetReportingPage,
  WxGetCheckPage,
  WxUpdateVaccinateStatus,
  WxGetOutReportingPage,
  WxGetNATestPage,
} from "@/api/RealInfo";
import { setOpenId, getOpenId } from "@/utils/auth";
import { Dialog } from "vant";
import { Toast } from "vant";
import wx from "weixin-js-sdk";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      riKind: 0, //tab栏
      list: {}, //反洛
      ymlist: {}, //疫苗接种 
      listfrom: {
        page: 0,
        limit: 10,
      }, //查询传参
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      showTc: false,
      Status: false,
      jiezhongTime: false,
      minDate: new Date(2018, 0, 1),
      maxDate: new Date(2030, 10, 1),
      dataform: {},
      jiezhognDate: "",
      yimiaolist: [
        { id: "1", Name: "未接种" },
        { id: "2", Name: "第一针" },
        { id: "3", Name: "第二针" },
        { id: "4", Name: "第三针" },
      ],
      datafrom: {},
      zaiwailist: {},
      hslist: {},
    };
  },
  filters: {
    capitalizeTime: function (value) {
      if (!value) return "";
      let gstime = value.substring(0, 10);
      return gstime;
    },
  },
  methods: {
    // 反洛报备列表
    getList () {
      //   this.listfrom.riKind = this.riKind;
      this.yimiaoList()
      // WxGetReportingPage({
      //   openID: getOpenId(),
      //   page: 1,
      //   limit: 99,
      // })
      //   .then((res) => {
      //     if (res.data.code == 0) {
      //       if (res.data.count == 0) {
      //         // this.hidden = true;
      //         this.finished = true;
      //         this.list = {};
      //       } else {
      //         this.list = res.data.data;
      //         this.total = res.data.count;
      //         this.finished = true;
      //         // this.hidden = false;
      //         for (var i = 0; i < this.list.length; i++) {
      //           var item = this.list[i].IssTime;
      //           item = this.dataFormat(item);
      //           this.list[i].IssTime = item;
      //         }
      //       }
      //     }
      //   })
      //   .catch(() => { });
    },
    //编辑
    fanqiUpdata (ID) {
      console.log(ID);
      this.$router.push({
        path: "/register/fangyi/myFangKong/fkFanQi/fanQiHs/" + ID,
      });
    },
    //详情
    fanqixiangqing (ID) {
      console.log(ID);
      this.$router.push({
        path: "/register/fangyi/myFangKong/fkFanQi/mkfqXiangQing/" + ID,
      });
    },
    //在外详情
    zaiwaixiangqing (row) {
      console.log(row);
      var itemmark = JSON.stringify(row);
      console.log(itemmark);
      this.$router.push({ name: 'zaiweixiangqing', params: { id: itemmark } })
      // this.$router.push({
      //   path: "/fangyi/fangkong/fkShouYe/zaiweixiangqing/" + itemmark,
      // });
    },
    //核酸检测详情
    hesuanXq (row) {
      console.log(row);
      var itemmark = JSON.stringify(row);
      console.log(itemmark);
      this.$router.push({ name: 'hesuanXq', params: { id: itemmark } })

    },
    // 在外人员
    zaiwaiList () {
      WxGetOutReportingPage({
        openID: getOpenId(),
        page: 1,
        limit: 99,
      })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              // this.hidden = true;
              this.finished = true;
              this.zaiwailist = {};
            } else {
              this.zaiwailist = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              // this.hidden = false;
              for (var i = 0; i < this.zaiwailist.length; i++) {
                var item = this.zaiwailist[i].IssTime;
                item = this.dataFormat(item);
                this.zaiwailist[i].IssTime = item;
              }
            }
          }
        })
        .catch(() => { });
    },
    // 疫苗接种
    yimiaoList () {
      WxGetCheckPage({
        openID: getOpenId(),
        page: 1,
        limit: 99,
      })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              // this.hidden = true;
              this.finished = true;
              this.ymlist = {};
            } else {
              this.ymlist = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              // this.hidden = false;
              for (var i = 0; i < this.ymlist.length; i++) {
                var item = this.ymlist[i].IssTime;
                item = this.dataFormat(item);
                this.ymlist[i].IssTime = item;
              }
            }
          }
        })
        .catch(() => { });
    },
    //更新疫苗接种信息
    yimiaoUpdata (row) {
      console.log(row);
      this.showTc = true;
      this.datafrom.ID = row.ID;
      this.datafrom.Status = row.Status;
      this.datafrom.StatusDesc = row.StatusDesc;
      this.datafrom.VaccinateTime = row.VaccinateTime.substring(0, 10);
    },
    confirm: function () {
      if (!this.datafrom.Status) {
        Toast.fail("请选择接种情况");
        return false;
      }
      if (!this.datafrom.VaccinateTime) {
        Toast.fail("请选择接种日期");
        return false;
      } else {
        WxUpdateVaccinateStatus({
          ID: this.datafrom.ID,
          Status: this.datafrom.Status,
          VaccinateTime: this.datafrom.VaccinateTime,
        })
          .then((res) => {
            console.log(res.data.code);
            if (res.data.code == 0) {
              Toast.success("操作成功");
              this.riKind = "2";
              this.yimiaoList();
            } else {
              Toast.fail(res.data.msg);
            }
          })
          .catch(() => { });
      }

      // this.showTc = true
    },
    // 疫苗选择器
    onStatus (val) {
      this.datafrom.Status = val.id;
      this.datafrom.StatusDesc = val.Name;
      this.datafrom.VaccinateTime = "";
      this.Status = false;
    },
    onjiezhongFend (val) {
      //接种时间 确认
      this.datafrom.VaccinateTime = this.dateformat(val);
      this.jiezhongTime = false;
    },
    dateformat (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
    // 下拉刷新
    onLoad () {
      console.log(123);
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // tan点击切换事件
    onClick (e) {
      this.riKind = e;
      this.listfrom.page = 1;
      if (e == "0") {
        this.getList();
      } else if (e == "1") {
        // this.zaiwaiList();
        this.getNATestPage();
      } else if (e == "2") {
        this.yimiaoList();
      }
    },
    // 核酸检测登记
    getNATestPage () {
      WxGetNATestPage({
        openID: getOpenId(),
        page: 1,
        limit: 99,
      })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              // this.hidden = true;
              this.finished = true;
              this.hslist = {};
            } else {
              this.hslist = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              // this.hidden = false;
              for (var i = 0; i < this.hslist.length; i++) {
                var item = this.hslist[i].IssTime;
                item = this.dataFormat(item);
                this.hslist[i].IssTime = item;
              }
            }
          }
        })
        .catch(() => { });
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted () {
    this.$nextTick(function () {
      this.getList();
    });
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      // this.$store.dispatch('user/setopenid', { openid: this.$route.query['openid'] })
      setOpenId(this.$route.query["openid"]);
    }
  },
};
</script>
<style>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
.van-tabs__content {
  border-top: 10px solid rgb(247, 247, 247);
}
.van-tabs__wrap--scrollable {
  margin-bottom: 5px;
}
</style>